<template>
  <div>
    <div class="ekifa-comment-list">
      <div class="comment-group-title">
        <h2>3 Comments</h2>
      </div>
      <div class="single-comment-item">
        <div class="single-comment-box">
          <div class="main-comment">
            <div class="author-image">
              <img :src="require('../../assets/img/author-7.jpg')" alt="author" />
            </div>
            <div class="comment-text">
              <div class="comment-info">
                <h4>david kamal</h4>
                <p>12 february 2015 At 11:30 pm</p>
              </div>
              <div class="comment-text-inner">
                <p>Ne erat velit invidunt his. Eum in dicta veniam interesset, harum lupta definitionem. Vocibus suscipit prodesset vim ei, equidem perpetua eu per.</p>
              </div>
              <b-link class="reply">Reply <i class="fa fa-share"></i></b-link>
            </div>
          </div>
        </div>
        <div class="single-comment-box reply-comment">
          <div class="main-comment">
            <div class="author-image">
              <img :src="require('../../assets/img/author-6.jpg')" alt="author" />
            </div>
            <div class="comment-text">
              <div class="comment-info">
                <h4>Sharlin Farzana</h4>
                <p>12 february 2015 At 11:30 pm</p>
              </div>
              <div class="comment-text-inner">
                <p>Ne erat velit invidunt his. Eum in dicta veniam interesset, harum lupta definitionem. Vocibus suscipit prodesset vim ei, equidem perpetua eu per.</p>
              </div>
              <b-link class="reply">Reply <i class="fa fa-share"></i></b-link>
            </div>
          </div>
        </div>
        <div class="single-comment-box">
          <div class="main-comment">
            <div class="author-image">
              <img :src="require('../../assets/img/author-8.jpg')" alt="author" />
            </div>
            <div class="comment-text">
              <div class="comment-info">
                <h4>Mr. Alex</h4>
                <p>12 february 2015 At 11:30 pm</p>
              </div>
              <div class="comment-text-inner">
                <p>Ne erat velit invidunt his. Eum in dicta veniam interesset, harum lupta definitionem. Vocibus suscipit prodesset vim ei, equidem perpetua eu per.</p>
              </div>
              <b-link class="reply">Reply <i class="fa fa-share"></i></b-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="ekifa-leave-comment ">
      <h2>Leave a comment</h2>
      <p>Your must sing-in to make or comment a post</p>
      <b-form>
        <b-form-group id="input-group-1" label-for="input-1">
          <b-form-input
              id="input-1"
              v-model="message.name"
              placeholder="Enter Your Name"
              required
          ></b-form-input>
        </b-form-group>
        <b-form-group
            id="input-group-2"
            label-for="input-2"
        >
          <b-form-input
              id="input-2"
              v-model="message.email"
              type="email"
              placeholder="Enter Your Email"
              required
          ></b-form-input>
        </b-form-group>
        <b-form-group id="input-group-4" label-for="input-4">
          <b-form-textarea
              id="input-4"
              size="lg"
              v-model="message.comment"
              placeholder="Comment..."
          ></b-form-textarea>
        </b-form-group>
        <b-button type="submit" name="submit">post comment</b-button>
      </b-form>
<!--      <form>
        <input class="ns-com-name" name="name" placeholder="Name" type="text">
        <input class="ns-com-name" name="email" placeholder="Email" type="email">
        <textarea class="comment" placeholder="Comment..." name="comment"></textarea>
        <button type="submit" >post comment</button>
      </form>-->
    </div>
  </div>
</template>

<script>
export default {
  name: "Comments",
  data() {
    return {
      message: {
        name: '',
        email: '',
        comment: ''
      }
    }
  }
}
</script>

<style scoped>

</style>