/*=========================================================================================
File Name: SingleBlog.vue
Description: Single Blog Page.
----------------------------------------------------------------------------------------
Item Name: Ekifa Vue || Business and Agency VueJs Template
Version: 1.0.0
Author: Deveable
Copyright 2021
==========================================================================================*/
<template>
<div>
  <!-- Breadcrumb Component -->
  <breadcrumb-area :breadcrumb="breadcrumb"/>
  <!-- Blog Details Area Component -->
  <blog-details-area/>
</div>
</template>

<script>
import BreadcrumbArea from "../../components/BreadcrumbArea";
import BlogDetailsArea from "../../components/blog/BlogDetailsArea";
export default {
  name: "SingleBlog",
  components: {BlogDetailsArea, BreadcrumbArea},
  data() {
    return {
      breadcrumb: {
        page: "Single Blog",
        pageTitle: "Blog Details"
      }
    }
  }
}
</script>

<style scoped>

</style>